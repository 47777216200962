import {
  Annotations as PlotlyAnnotation,
  Data as PlotlyData,
  Shape as PlotlyShape, ShapeLine
} from 'plotly.js';
import { ChartDataBounds, ProjectStageDate, ProjectStageSeriesDataSets } from '../types';

/**
 * Converts a series name like "Actual Cost" to a camel case key like "actualCost".
 */
function keyFromName(name: string) {
  return (name.charAt(0).toLocaleLowerCase() + name.slice(1)).replace(/\s/, '');
}

/**
 * Returns the minimum and maximum range for the data from the API response.
 * This is required to draw additional lines that covers the whole range.
 * @param apiDataSets The full API response.
 */
export function findDataBounds(apiDataSets: ProjectStageSeriesDataSets): ChartDataBounds {
  const bounds = {
    xMin: '9999-99-99',
    xMax: '0000-00-00',
    yMin: Infinity,
    yMax: -Infinity
  };

  apiDataSets.data.labels.forEach((date) => {
    // We don't need to create Date objects, since ISO8601 dates are lexicographically
    // sorted, so we can compare them directly as strings:
    if (date < bounds.xMin) { bounds.xMin = date; }
    if (date > bounds.xMax) { bounds.xMax = date; }
  });

  apiDataSets.data.datasets.forEach((dataset) => {
    dataset.data.forEach((value) => {
      if (value < bounds.yMin) { bounds.yMin = value; }
      if (value > bounds.yMax) { bounds.yMax = value; }
    });
  });

  return bounds;
}

/**
 * Returns the latest version of each kind of date from `apiDataSets.dates`.
 * @param apiDataSets The full API response.
 * @returns An array with the most recent dates for each kind.
 */
export function getLatestDates(apiDataSets: ProjectStageSeriesDataSets) {
  const latestDatesMap = new Map<string, ProjectStageDate>();
  apiDataSets.dates?.forEach((date) => {
    const existingDateKind = latestDatesMap.get(date.kind);
    if (!existingDateKind || date.version > existingDateKind.version) {
      latestDatesMap.set(date.kind, date);
    }
  });

  return Array.from(latestDatesMap.values());
}

/**
 * Creates the PlotlyData array for the plotly chart.
 * @param apiDataSets The full API response.
 */
export function generatePlotData(
  apiDataSets: ProjectStageSeriesDataSets,
  colorSchema: { [key: string]: string }
) {
  const plotData: PlotlyData[] = [];

  // Reverse the api response order for a better looking chart
  apiDataSets.data.datasets.reverse().forEach((dataSet) => {
    plotData.push({
      x: apiDataSets.data.labels,
      y: dataSet.data,
      mode: 'lines',
      name: dataSet.label,
      line: {
        color: colorSchema[keyFromName(dataSet.label)],
        width: 4
      }
    });
  });

  const dates = getLatestDates(apiDataSets);
  const textsByDate: Record<string, string> = {};
  dates.forEach((date) => {
    const key = date.value;
    if (textsByDate[key]) {
      textsByDate[key] += ` | ${date.name}`;
    } else {
      textsByDate[key] = date.name;
    }
  });

  plotData.push({
    name: 'Dates',
    mode: 'markers',
    marker: {
      color: colorSchema.dateLineSecondary,
      size: 10,
    },
    x: Object.keys(textsByDate),
    y: Array(Object.keys(textsByDate).length).fill(0),
    text: Object.values(textsByDate),
    hovertemplate: '%{x}: %{text}'
  });

  return plotData;
}

/**
 * Creates additional lines (shapes) to be shown as indicators of common
 * chart elements, as the start/end of the project or the current date.
 * @param apiDataSets Full API response.
 * @param bounds The min and max values in the API response, computed with findDataBounds().
 * @param colorSchema A dictionary with the colors to use for each graphic component.
 * @param today An optional date for the "today" line. If missing this line is not included.
 */
export function generatePlotShapes(
  apiDataSets: ProjectStageSeriesDataSets,
  bounds: ChartDataBounds,
  colorSchema: { [key: string]: string },
  today?: Date
) {
  const plotShapes: Partial<PlotlyShape>[] = [];
  const makeHorizontalLine = (value: number, color: string, layer:'below' | 'above' = 'below'): Partial<PlotlyShape> => ({
    type: 'line',
    line: { color },
    x0: bounds.xMin,
    x1: bounds.xMax,
    y0: value,
    y1: value,
    layer
  });
  const makeVerticalLine = (date: string, line: Partial<ShapeLine>, layer:'below' | 'above' = 'below'): Partial<PlotlyShape> => ({
    type: 'line',
    x0: date,
    x1: date,
    y0: bounds.yMin,
    y1: bounds.yMax,
    line,
    layer
  });
  const startDate = apiDataSets.data.labels[apiDataSets.startDateIndex];
  const endDate = apiDataSets.data.labels[apiDataSets.endDateIndex];

  plotShapes.push(makeHorizontalLine(apiDataSets.budget, colorSchema.valueBaseline));
  plotShapes.push(makeHorizontalLine(apiDataSets.referenceCost, colorSchema.costBaseline));
  plotShapes.push(makeVerticalLine(startDate, { color: colorSchema.dateLine }, 'above'));
  plotShapes.push(makeVerticalLine(endDate, { color: colorSchema.dateLine }, 'above'));
  if (today) {
    plotShapes.push(makeVerticalLine(today.toJSON().substring(0, 'YYYY-MM-DD'.length), { color: colorSchema.todayLine }, 'above'));
  }

  getLatestDates(apiDataSets).forEach((date) => {
    plotShapes.push(makeVerticalLine(date.value, { color: colorSchema.dateLineSecondary, dash: 'dot' }));
  });

  return plotShapes;
}

/**
 * Creates labels for the additional lines added to the chart.
 * @param apiDataSets Full API response.
 * @param colorSchema A dictionary with the colors to use for each graphic component.
 * @param today An optional date for the "today" line. If missing this line is not included.
 */
export function generatePlotAnnotations(
  apiDataSets: ProjectStageSeriesDataSets,
  colorSchema: { [key: string]: string },
  today?: Date
) {
  const plotAnnotations: Partial<PlotlyAnnotation>[] = [];
  const baseHorizontalLine: Partial<PlotlyAnnotation> = { xref: 'paper', yref: 'y', showarrow: false };
  const baseVerticalLine: Partial<PlotlyAnnotation> = { xref: 'x', yref: 'paper', showarrow: false };
  const startDate = apiDataSets.data.labels[apiDataSets.startDateIndex];
  const endDate = apiDataSets.data.labels[apiDataSets.endDateIndex];

  plotAnnotations.push({
    ...baseHorizontalLine,
    text: 'Budget',
    x: 0.05,
    y: apiDataSets.budget,
    bgcolor: colorSchema.valueBaseline
  });

  plotAnnotations.push({
    ...baseHorizontalLine,
    text: 'Reference Cost',
    x: 0.05,
    y: apiDataSets.referenceCost,
    bgcolor: colorSchema.costBaseline
  });

  plotAnnotations.push({
    ...baseVerticalLine,
    text: 'Start date',
    x: startDate,
    y: 0.25,
    bgcolor: colorSchema.dateLine
  });

  plotAnnotations.push({
    ...baseVerticalLine,
    text: 'End date',
    x: endDate,
    y: 0.25,
    bgcolor: colorSchema.dateLine
  });

  if (today) {
    plotAnnotations.push({
      ...baseVerticalLine,
      text: 'Today',
      x: today.toJSON().substring(0, 'YYYY-MM-DD'.length),
      y: 0.5,
      bgcolor: colorSchema.todayLine
    });
  }

  return plotAnnotations;
}
