$(() => {
  const $tables = $('table.root-table');
  $tables.each((_, table) => {
    const noSortIndexs = $(table).find('th.not-sortable').index();
    const modelOrdering = JSON.parse(
      document.getElementById('model-ordering').textContent
    );
    const order = modelOrdering ? [] : [[0, 'desc']];

    $(table).DataTable({
      columnDefs: [
        {
          orderable: false,
          targets: noSortIndexs
        }
      ],
      pageLength: 100,
      order,
      scrollToTop: true,
    });
  });
});
