import { PlannerData, ResourcesData, Selection } from './planner-data.interface';
import { dateStr, getDatesRange } from './utils';

const today = new Date();

function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
const csrftoken = getCookie('csrftoken');

export type Sorting = 'name' | '-name';

/**
 * Interface for API objects that can be sorted by name.
 */
interface NamedAPIElement {
  name: string
}

/**
 * Sorts in place an array of data from the API based on the "name" attribute of it elements.
 */
function sortByName(array: NamedAPIElement[], order: Sorting) {
  array.sort((r1, r2) => {
    if (!r1 || !r1.name) {
      return 1;
    }

    const comparison = r1.name.localeCompare(r2?.name);
    return order === '-name' ? -comparison : comparison;
  });
}

export class PlannerApi {
  static data: PlannerData = {
    resourceGroups: [],
    groupBy: 'PROJECT_STAGE_THEN_USER',
    dates: {
      min: dateStr(new Date(today.getFullYear(), today.getMonth(), 1)),
      max: dateStr(new Date(today.getFullYear(), today.getMonth() + 1, 1))
    },
    holidays: [],
    totals: {
      clf: 0,
      plannedHours: 0,
      rate: 0,
      reportedHours: 0,
      unusedHours: 0,
      workloads: {},
    },
    timeResolution: 'DAILY',
    order: '',
  };

  static isLoading = false;

  static dataCallbacks: ((data: PlannerData) => void)[] = [];

  /**
   * Registers a new callback from a component that needs to
   * be updated about changes in the planning data from the API.
   * @param cb Callback.
   */
  static register(cb: (data: PlannerData) => void) {
    this.dataCallbacks.push(cb);
  }

  /**
   * Adds a query string to an given url with data in a
   * form with "filter-form" id
   *  @param url Given url to add query string
   */
  static addQueryStringToUrl(url: string) {
    const form = document.getElementById('filter-form') as HTMLFormElement;
    let urlWithQueryString = null;

    if (form) {
      // https://github.com/microsoft/TypeScript/issues/30584
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formData: any = new FormData(form);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const asString = new URLSearchParams(formData).toString();

      urlWithQueryString = `${url}?${asString}`;
    } else {
      const ganttFilersContainer = document.getElementById('gantt-filters');
      if (ganttFilersContainer && ganttFilersContainer.textContent) {
        const ganttFilters = JSON.parse(ganttFilersContainer.textContent) as Record<string, string>;
        // Actually "Record<string, something_with_toString>" but whatever.
        const queryParams = new URLSearchParams(
          Object.entries(ganttFilters).flatMap(([key, value]): [string, string][] => {
            if (Array.isArray(value)) {
              return value.map((v) => [key, String(v)]);
            }
            return [[key, String(value)]];
          })
        );
        const asString = queryParams.toString();
        urlWithQueryString = `${url}?${asString}`;
      }
    }

    return urlWithQueryString;
  }

  /**
   * Get the planning resources from the API
   * @param callback Callback
   */
  static getPlanningResources(callback: (result: ResourcesData[]) => void) {
    const url = this.addQueryStringToUrl('/api/v1/planning/resources/');

    if (url) {
      void fetch(url)
        .then((res) => res.json())
        .then(
          (result: ResourcesData[]) => {
            callback(result);
          }
        );
    }
  }

  /**
   * Updates the planning data from the API, notifying registered
   * components where the fetch is completed.
   */
  static fetch(defaultSort = true) {
    if (defaultSort) {
      this.setOrderInputValue('name');
    }

    const url = this.addQueryStringToUrl('/api/v1/planning/');

    if (url) {
      this.isLoading = true;

      void fetch(url)
        .then((res) => res.json())
        .then(
          (result: PlannerData) => {
            this.dataCallbacks.forEach((cb) => {
              this.data = result;

              // This should be done on success:
              cb(this.data);
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  /**
   * Updates the viewed interval. Automatically fetchs new data from the API.
   * @param minDate Optional start date with format yyyy-mm-dd.
   * @param maxDate Optional end date with format yyyy-mm-dd.
   */
  static setDates(minDate: string | undefined, maxDate: string | undefined) {
    let shouldUpdate = false;
    if (minDate) {
      this.data = {
        ...this.data,
        dates: {
          min: minDate,
          max: this.data.dates.max
        }
      };
      shouldUpdate = true;
    }
    if (maxDate) {
      this.data = {
        ...this.data,
        dates: {
          min: this.data.dates.min,
          max: maxDate
        }
      };
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      this.fetch(false);
    }
  }

  /**
   * Call this to perform the update action.
   * It will automatically trigger a fetch() call when completed.
   * @param selection The dates range to update.
   * @param plannedHours The new value for the planned hours in the selection interval.
   */
  static updateWorkloads(selection: Selection, plannedHours: number) {
    const body = {
      ...selection,
      plannedHours,
      groupBy: this.data.groupBy
    };

    const request = new Request(
      '/api/v1/planning/update/',
      {
        headers: {
          'X-CSRFToken': csrftoken || '',
          'Content-Type': 'application/json'
        }
      }
    );

    void fetch(request, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(() => {
      this.fetch(false);
    });

    const resource = this.data.resourceGroups.find(
      (rg) => rg.id === selection.resourceGroupId
    )?.resources.find(
      (r) => r.id === selection.resourceId && r.roleId === selection.roleId
    );

    if (resource && selection.startDate && selection.endDate) {
      const dates = getDatesRange(selection.startDate, selection.endDate);
      dates.forEach((date) => {
        const key = dateStr(date);
        resource.workloads[key] = {
          reportedHours: resource.workloads[key]?.reportedHours || 0,
          plannedHours
        };
      });
    }
  }

  /**
   * Sets the value of the order input in the filters form. It toggles the
   * value according to the received order string and the input's previous value.
   * @param order The base string to set as the value
   */
  static setOrderInputValue(order: string) {
    const form = document.getElementById('filter-form') as HTMLFormElement;
    if (!form) return;

    let orderInput = form.querySelector<HTMLInputElement>('input[name="o"]');
    if (!orderInput) {
      orderInput = document.createElement('input');
      orderInput.setAttribute('name', 'o');
      orderInput.setAttribute('type', 'hidden');
      form.appendChild(orderInput);
    }
    orderInput.value = order;
  }

  /**
   * Updates the planning data from the API, fetching by the specified order.
   * @param order The string by which the fetch results will be ordered.
   */
  static fetchByOrder(data: PlannerData, order: Sorting = 'name') {
    this.setOrderInputValue(order);
    this.dataCallbacks.forEach((cb) => {
      sortByName(data.resourceGroups, order);
      cb(data);
    });
  }
}
