import React, {
  useReducer, useMemo, useCallback, useEffect
} from 'react';
import './planner.scss';
import { FreezedPane } from './components/freezed-pane';
import { ScrollablePane } from './components/scrollable-pane';
import {
  PlannerData,
  Selection,
  Action,
  ResourceInGroup,
  ResourcesData,
  ResourceGroup,
  Totals,
  TimeResolution
} from './planner-data.interface';
import { getDatesRange, dateStr } from './utils';
import { Popup } from './components/popup';
import { PlannerApi, Sorting } from './planner.api';
import { ABORT_POSITION_CB_IDENTIFIER } from './constants';

// State definition for the component state
type State = {
  resources: ResourcesData[],
  selection: Selection,
  resourceGroups: PlannerData['resourceGroups'],
  dates: PlannerData['dates'],
  popup: {
    position: { x: number, y: number },
    show: 'buttons' | 'create' | 'delete' | 'update' | 'none'
  },
  holidays: string[],
  totals: Totals,
  timeResolution: TimeResolution,
  order: string
};

// Reducer (based on react reducer)
const reducer = (state: State, action: Action): State => {
  // Next state for the popup.show state, for mouse up actions:
  let showNext = state.popup.show;

  switch (action.type) {
    case 'closePopup':
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'none'
        }
      };
    case 'mouseUp':
      if (state.selection.resourceId > 0) {
        showNext = 'buttons';
      } else {
        // selecting on a resourceGroup
        showNext = 'create';
      }

      // Detect right-to-left selection ranges to cancel the pop-up
      if (state.selection.endDate && state.selection.startDate) {
        if (state.selection.endDate < state.selection.startDate) {
          showNext = 'none';
        }
      }

      return {
        ...state,
        selection: {
          ...state.selection,
          isSelecting: false
        },
        popup: {
          ...state.popup,
          show: showNext,
          position: {
            x: action.x,
            y: action.y
          }
        }
      };
    case 'mouseDown':
      return {
        ...state,
        selection: {
          startDate: action.date,
          endDate: action.date,
          resourceGroupId: action.resourceGroupId,
          resourceId: action.resourceId,
          roleId: action.roleId,
          isSelecting: true
        },
        popup: {
          ...state.popup,
          show: 'none'
        }
      };
    case 'mouseEnter':
      return state.selection.isSelecting ? {
        ...state,
        selection: {
          ...state.selection,
          endDate: action.date
        }
      } : state;
    case 'newData':
      return {
        ...state,
        resourceGroups: action.data.resourceGroups,
        dates: action.data.dates,
        holidays: action.data.holidays,
        totals: action.data.totals,
        timeResolution: action.data.timeResolution,
      };
    case 'createSelection':
      PlannerApi.updateWorkloads(
        {
          ...state.selection,
          resourceId: action.resourceId,
          roleId: null
        }, action.hours);
      // TODO: Add an 'updating' state
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'none'
        }
      };
    case 'updateSelection':
      PlannerApi.updateWorkloads(state.selection, action.hours);
      // TODO: Add an 'updating' state
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'none'
        }
      };
    case 'deleteSelection':
      PlannerApi.updateWorkloads(state.selection, 0);
      // TODO: Add an 'updating' state
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'none'
        }
      };
    case 'showUpdate':
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'update'
        }
      };
    case 'showDelete':
      return {
        ...state,
        popup: {
          ...state.popup,
          show: 'delete'
        }
      };
    case 'changeOrder': {
      const data = {
        resourceGroups: [...state.resourceGroups],
        dates: state.dates,
        holidays: state.holidays,
        totals: state.totals,
        timeResolution: state.timeResolution,
      };
      PlannerApi.fetchByOrder(data as PlannerData, action.order as Sorting);
      return {
        ...state,
        order: action.order
      };
    }
    case 'updateResources':
      return {
        ...state,
        resources: action.data
      };
    default:
      throw new Error(`Unexpected action type in reducer: ${(action as Action).type}`);
  }
};

const getResourcesList = (resources: ResourcesData[]) => {
  const resourcesDict: ResourceInGroup[] = resources.map(({ id, name }) => (
    { id, name } as ResourceInGroup)
  );

  return resourcesDict;
};

/**
 * Returns the minimun amount of planned hours from a selection
 * @param selection: Selection
 * @param resourceGroups: ResourceGroup[]
 * @returns number
 */
const getMinimumPlannedHours = (selection: Selection, resourceGroups: ResourceGroup[]) => {
  const DEFAULT_MIN_HOURS = 0;

  const selectedResourceGroup = resourceGroups.find((rg) => rg.id === selection.resourceGroupId);
  if (!selectedResourceGroup) return DEFAULT_MIN_HOURS;

  const selectedResource = selectedResourceGroup.resources.find(
    (r) => r.id === selection.resourceId
  );
  if (!selectedResource) return DEFAULT_MIN_HOURS;

  const workloadsKeys = Object.keys(selectedResource.workloads);
  const selectedPlannedHours: number[] = [];

  workloadsKeys.forEach((key) => {
    if (
      Date.parse(key) >= Date.parse(selection.startDate!)
      && Date.parse(key) <= Date.parse(selection.endDate!)
    ) {
      selectedPlannedHours.push(selectedResource.workloads[key].plannedHours);
    }
  });

  return Math.min(...selectedPlannedHours);
};

/**
 * Returns the main planner component.
 * @param props It should contain planner data.
 */
export function Planner() {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, {
    resources: [],
    resourceGroups: [],
    selection: {
      startDate: null,
      endDate: null,
      resourceGroupId: -1,
      resourceId: -1,
      roleId: -1,
      isSelecting: false
    },
    dates: {
      min: dateStr(new Date()),
      max: dateStr(new Date())
    },
    popup: {
      show: 'none',
      position: { x: 0, y: 0 }
    },
    holidays: [],
    totals: {
      clf: 0,
      plannedHours: 0,
      rate: 0,
      reportedHours: 0,
      unusedHours: 0,
      workloads: {},
    },
    timeResolution: 'DAILY',
    order: 'name'
  });
  const datesRange = useMemo(
    () => getDatesRange(state.dates.min, state.dates.max, state.timeResolution),
    [state.dates, state.timeResolution]
  );
  const positionCb = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.classList.contains(ABORT_POSITION_CB_IDENTIFIER)) return;

    dispatch({
      type: 'mouseUp',
      x: e.pageX - (e.currentTarget.getBoundingClientRect().left + window.scrollX),
      y: e.pageY - (e.currentTarget.getBoundingClientRect().top + window.scrollY)
    });
  }, [true]);
  useEffect(() => {
    PlannerApi.register((data: PlannerData) => dispatch({ type: 'newData', data }));
    PlannerApi.fetch();
  }, []);

  const minimumPlannedHours = useMemo(
    () => getMinimumPlannedHours(state.selection, state.resourceGroups),
    [state.selection]
  );

  // Render
  return (state.resourceGroups.length > 0 ? (
    <div
      className="planner planner-container"
      onMouseUp={positionCb}
      role="presentation"
    >
      <FreezedPane
        resourceGroups={state.resourceGroups}
        totals={state.totals}
        dispatch={dispatch}
        order={state.order}
      />
      <ScrollablePane
        resourceGroups={state.resourceGroups}
        datesRange={datesRange}
        selection={state.selection}
        dispatch={dispatch}
        holidays={state.holidays}
        totals={state.totals}
      />
      <Popup
        position={state.popup.position}
        show={state.popup.show}
        resources={state.resources as ResourceInGroup[]}
        dispatch={dispatch}
        defaultHours={minimumPlannedHours}
      />
    </div>
    // TODO: Handle what to show when api call fails or data is empty
  ) : <div />);
}
