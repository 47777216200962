import { Data as PlotlyData } from 'plotly.js';
import { Sale } from '../types';

export const generateSalesPlotData = (sales: Sale[]) => {
  const plotData: PlotlyData[] = [];
  const xLabels = [...new Set(sales.map((sale) => sale.saleDate.slice(0, 7)))];
  const salesGroupedByDate = xLabels.map(
    (date) => sales
      .filter((sale) => sale.saleDate.includes(date))
      .sort((a, b) => b.priceClf - a.priceClf)
  );
  salesGroupedByDate.forEach((salesGroup) => {
    const salesGroupTotal = salesGroup
      .reduce((accum, current) => accum + current.priceClf, 0)
      .toFixed(2);

    salesGroup.forEach((sale, index) => {
      plotData.push({
        x: [sale.saleDate.slice(0, 7)],
        y: [sale.priceClf],
        type: 'bar',
        hovertemplate: `${sale.saleDate}<br>%{y} UF<br>%{customdata}<extra></extra>`,
        text: index === salesGroup.length - 1 ? salesGroupTotal : undefined,
        textposition: 'outside',
        customdata: [sale.projectStageName],
        marker: {
          color: index % 2 === 0 ? '#0083ba' : '#0dcaf0',
        },
      });
    });
  });

  return plotData;
};
