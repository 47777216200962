import React, {
  ReactElement,
  useEffect,
  useId,
  useState
} from 'react';
import { Data as PlotlyData } from 'plotly.js';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { JsonPropsType } from '../../component-loader';
import { Sale, SaleReportDateRange } from './types';
import { generateSalesPlotData } from './helpers/dataProcessing';

export type SalesReportChartProps = {
  jsonObject: {
    dataUrl: string
  }
};

export function SalesReportChart(props: JsonPropsType) {
  const { jsonObject } = props as SalesReportChartProps;

  if (!jsonObject.dataUrl) {
    throw new Error('Missing props for SalesReportChart component.');
  }

  const DATE_FORMAT = 'YYYY-MM-DD';
  const MIN_HEIGHT = '500px';

  const [sales, setSales] = useState<PlotlyData[] | undefined>(undefined);
  const [dateRange, setDateRange] = useState<SaleReportDateRange>({
    from: dayjs().subtract(1, 'year').startOf('month').format(DATE_FORMAT),
    to: dayjs().format(DATE_FORMAT),
  });
  const [loading, setLoading] = useState(false);

  const fromInputId = useId();
  const toInputId = useId();

  useEffect(() => {
    const fetchSales = async () => {
      const fetchParams = new URLSearchParams({
        sale_date_after: dateRange.from,
        sale_date_before: dateRange.to
      });

      setLoading(true);
      const res = await fetch(`${jsonObject.dataUrl}?${fetchParams.toString()}`);
      const fetchedSales = await res.json() as Sale[];
      setSales(generateSalesPlotData(fetchedSales));
      setLoading(false);
    };

    fetchSales().catch(console.error);
  }, [dateRange]);

  const generateGraph = (): undefined | ReactElement => {
    if (!sales) return undefined;
    if (!sales.length) {
      return (
        <p style={{ minHeight: MIN_HEIGHT }} className="d-flex justify-content-center mt-3">
          No existen ventas en el rango seleccionado.
        </p>
      );
    }

    return (
      <Plot
        data={sales}
        layout={{
          barmode: 'stack',
          xaxis: {
            tickformat: '%Y-%m-%d',
            tickmode: 'linear',
            rangemode: 'tozero',
            type: 'category'
          },
          yaxis: {
            linewidth: 1,
            rangemode: 'tozero',
            title: {
              text: 'CLF',
            },
          },
          showlegend: false
        }}
        useResizeHandler
        style={{ minWidth: '100%', minHeight: MIN_HEIGHT }}
        config={{
          displaylogo: false,
          displayModeBar: false,
          showAxisDragHandles: false,
        }}
      />
    );
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-md-6">
          <div className="input-group mb-3">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="input-group-text" htmlFor={fromInputId}>From: </label>
            <input
              type="date"
              id={fromInputId}
              className="form-control"
              value={dateRange.from}
              onChange={(e) => setDateRange((prev) => ({ ...prev, from: e.target.value }))}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-3">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="input-group-text" htmlFor={toInputId}>To: </label>
            <input
              type="date"
              id={toInputId}
              className="form-control"
              value={dateRange.to}
              onChange={(e) => setDateRange((prev) => ({ ...prev, to: e.target.value }))}
            />
          </div>
        </div>
      </div>

      <div className="position-relative" style={{ minHeight: '3rem' }}>
        {loading && (
          <div className="chart-loading">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {generateGraph()}
      </div>
    </>
  );
}
